import useForm from './useForm.js';
import React, { useCallback, useEffect, useState } from "react";
import validate from "@klickinc/util-validator";
import ruleSet from './ruleSet.js';
import { navigate } from "gatsby";
// Style Imports
import "./Form.scss"
// Utility Imports
import { getVisitorId, getApiEndpoint } from '@src/helper';
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3';

// Content Imports


const Unsubscribe = ({ googleReCaptchaProps }) => {
    const [submitting, setSubmitting] = useState(false);
    const getToken = useCallback(async () => {
        if (!googleReCaptchaProps.executeRecaptcha) {
            console.log('Recaptcha has not been loaded');
            return;
        }
        const _token = await googleReCaptchaProps.executeRecaptcha("SignupForm");
        return _token;
    }, [googleReCaptchaProps]);

    const success = useCallback((vals) => {
        fetch(getApiEndpoint() + 'allinforln/unsub', {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(vals),
        })
        .then(async (resp) => {
            var r = await resp.json();
            if (r.success) {
                gtmFormSuccess();
                gtmFormFieldCompletionSubmitted();
                navigate('/unsubscribe-confirmed',
                    {
                        state: {
                            values: vals, 
                            token: r.data.token
                        }  
                    }
                );
            } else {
                navigate('/500');
            }
        })
        .catch(() => {
            navigate('/500');
        })
    }, [])
    
    const handleAllChecked = (event)=>{
        event.persist();
        if(event.target.name === 'unsubscribeAll'){
            setValues(values => ({ ...values, [event.target.name]: event.target.checked,  unsubscribeAuriniaAlliance: event.target.checked, unsubscribeTreatment: event.target.checked, unsubscribeCorporate: event.target.checked}));
        }else{
            handleChange(event);
            setValues(values => ({ ...values, unsubscribeAll: false}));
        }
    }

    const valueChangeCallback = useCallback((vals) => {
        if(vals.unsubscribeAuriniaAlliance && vals.unsubscribeTreatment && vals.unsubscribeCorporate && vals.unsubscribeAll === false){
            setValues(values => ({ ...values, unsubscribeAll: true}));
        }
    }, [])

    //GTM
    const gtmFormSuccess = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event' : 'Form Submission',
            'Event_Category': 'Form Submissions', 
            'Event_Action': 'Unsubscribe',
            'Event_Label': 'Complete'
        });
    }

    const gtmFormFieldError = (errors) => {
        if ( errors ) {
            for(var error in errors){
                const selector = ".error--" + error;
                if(document.querySelector(selector)){
                    const errorText = document.querySelector(selector).innerText;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'Form Errors',
                        'Event_Category': 'Validation Errors', 
                        'Event_Action': 'Unsubscribe',
                        'Event_Label': error + '/' + errorText
                    });
                }
            }
            setSubmitting(false);
        }   
    }

    const gtmFieldCompletion = () => {
        document.querySelectorAll('.form--unsubscribe input').forEach( field => {
            if(!field.getAttribute('data-has-ga-listener')){
                field.setAttribute('data-has-ga-listener','true')
                field.addEventListener('change', fieldCompletionEvent);
            }
        });
    }

    const fieldCompletionEvent = function(e) {
        const field = e.target;
        let fieldInfo;
        switch(field.type){
            case ('radio'):
                fieldInfo = (field.getAttribute('gaVal')) ? (field.name + '_' + field.getAttribute('gaVal')) : 'No gaVal found';
                break;
            case ('checkbox'):
                fieldInfo = field.name + '_' + field.checked;
                break;
            default:
                fieldInfo = field.name
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event' : 'Field Completion',
            'Event_Category': 'Form Field Completion', 
            'Event_Action': 'Unsubscribe',
            'Event_Label': fieldInfo
        });
    }
    
    const gtmFormFieldCompletionSubmitted = () => {
        document.querySelectorAll('.form--unsubscribe input').forEach( field => {
            let fieldInfo;

            if ((field.type === 'radio' || field.type === 'checkbox') && !field.checked) return;
            if (field.value.length === 0) return;

            switch(field.type){
                case ('radio'):
                    fieldInfo = (field.getAttribute('gaVal')) ? (field.name + '_' + field.getAttribute('gaVal')) : 'No gaVal found';
                    break;
                case ('checkbox'):
                    fieldInfo = field.name + '_' + field.checked;
                    break;
                default:
                    fieldInfo = field.name
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event' : 'Form Field Completion_Form Submitted',
                'Event_Category': 'Form Field Completion_Form Submitted', 
                'Event_Action': 'Unsubscribe',
                'Event_Label': fieldInfo
            });
        })
    }

    useEffect(()=>{
        gtmFieldCompletion();
    })

    const [{
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues
    }] = useForm(success, validate, ruleSet.unsub.rules, {source: 'aurinia-allinforln-redesign-unsubscribe', visitorId : getVisitorId()}, valueChangeCallback, gtmFormFieldError);
    

    return (
        <section className="bg-light-blue">
            <div className="container container--narrow">
                <h1 className="text-left">Unsubscribe</h1>
                <p className="hero__subheading text-left"><strong>It's okay. We promise there are no hard feelings.</strong></p>
            </div>
            <div className="container container--narrow">
                <p className='text-left'>Simply enter your email address below to unsubscribe from email communications from ALL IN&reg;.</p>
                <form className="form--unsubscribe" onSubmit={(e)=>{e.preventDefault(); setSubmitting(true); getToken().then((token)=>{handleSubmit(e, {captchaResponse: token})});}}>
                    <div className="single-col-input">
                        <label>
                            Email Address*
                            <input aria-required="true" type="text" name="email" placeholder="e.g. jsmith@email.com" value={values.email || ''} onChange={handleChange} aria-invalid={errors?.email ? true : false} aria-labelledby={errors?.email ? "errorEmail" : null} maxLength={'50'}/>
                        </label>
                        {errors?.email && (
                            <p id="errorEmail" className="error error--email">Please input a valid email address</p>
                        )}  
                    </div>
                    <p className='text-left'>Are there any additional communications you would like to unsubscribe from?</p>
                    <div className="checkbox-wrapper">
                        <input name="unsubscribeAuriniaAlliance" id="unsubscribeAuriniaAllianceCheckbox" type="checkbox" checked={values.unsubscribeAuriniaAlliance} onChange={handleAllChecked} />
                        <label htmlFor="unsubscribeAuriniaAllianceCheckbox">Marketing communications about patient support from Aurinia Alliance&trade;</label>
                    </div>
                    <div className="checkbox-wrapper">
                        <input name="unsubscribeTreatment" id="unsubscribeTreatmentCheckbox" type="checkbox" checked={values.unsubscribeTreatment} onChange={handleAllChecked} />
                        <label htmlFor="unsubscribeTreatmentCheckbox">Information about an Aurinia treatment</label>
                    </div>
                    <div className="checkbox-wrapper">
                        <input name="unsubscribeCorporate" id="unsubscribeCorporateCheckbox" type="checkbox" checked={values.unsubscribeCorporate} onChange={handleAllChecked} />
                        <label htmlFor="unsubscribeCorporateCheckbox">Corporate communications from Aurinia</label>
                    </div>
                    <div className="checkbox-wrapper">
                        <input name="unsubscribeAll" id="unsubscribeAllCheckbox" type="checkbox" checked={values.unsubscribeAll} onChange={handleAllChecked} />
                        <label htmlFor="unsubscribeAllCheckbox">All Aurinia-affiliated communications</label>
                    </div>
                    <p className='text-left'>NOTE: If you are currently enrolled in Aurinia Alliance, you may still receive communications from your Nurse Case Manager or the program. If you have any questions about your enrollment, please call <a href="tel:18332874642">1-833-AURINIA (1-833-287-4642)</a>.</p>
                    <button type="submit" className={"cta cta--body cta--purple cta--unsubscribe" + (submitting ? " button--submitting" : "")}>Unsubscribe</button>
                </form>
            </div>
        </section>
    )
}

const UnsubscribeWithCaptcha = withGoogleReCaptcha(Unsubscribe);
export default function UnsubscribeWrapper () {
    return(
        <GoogleReCaptchaProvider reCaptchaKey="6LcuTWwhAAAAABVfYC6XlGMwTPOwpyiRXKdc5X4O" >
            <UnsubscribeWithCaptcha/>
        </GoogleReCaptchaProvider>
    )
}
